import React from "react";
import { makeStyles } from "@gfg/ui-v2/theming";
import { MainProfileInfo } from "@coral/typings";
import date from "~/common/services/dates";
import TableRow from "~/user-profile/components/profile/row";
import UserIcon from "~/common/icons/user-icon";

interface UserProfileProps {
  mainProfile: MainProfileInfo;
}

const mainProfileTranslations: { [key: string]: string } = {
  desktop: "Desktop",
  mobile: "Mobile",
  app_ios: "App iOS",
  app_android: "App Android",
};

const useStyles = makeStyles()(({ spacing, size, shadow, palette }) => ({
  box: {
    padding: spacing("lg"),
    borderRadius: size(4),
    backgroundColor: palette.background,
    border: `${size(1)} solid ${palette.gray[200]}`,
    boxShadow: shadow("md"),
  },
}));

const UserProfile: React.FC<UserProfileProps> = ({ mainProfile }) => {
  const { classes } = useStyles();

  const { profileId, lastUpdate, source, loggedUser, profile } = mainProfile;

  const profileData = [
    {
      icon: <UserIcon />,
      label: "User Profile ID",
      value: profileId,
    },
    {
      label: "Last accessed",
      value: date.format(new Date(+lastUpdate * 1e3)),
    },
    {
      label: "Source",
      value: source,
    },
    {
      label: "Logged user",
      value: loggedUser,
    },
  ];

  const gadgets = profile.map(item => ({
    label: mainProfileTranslations[item.key],
    value: item.value,
  }));

  return (
    <div className={classes.box}>
      <TableRow items={profileData} />
      <TableRow items={gadgets} />
    </div>
  );
};

export default UserProfile;
