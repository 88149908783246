import { HistoricalProfileAgeGenderRow } from "@coral/typings";
import { useMemo } from "react";

export default function useAgeGenderAffinity(items: HistoricalProfileAgeGenderRow[]) {
  return useMemo(() => {
    const _items = [...items];
    _items.sort((a, b) => parseFloat(b.score) - parseFloat(a.score));
    return _items.reduce(
      (acc, item) => {
        const { age, gender, score } = item;
        acc.age.push(age);
        acc.gender.push(gender);
        acc.score.push(score);
        return acc;
      },
      { age: [], gender: [], score: [] } as { age: string[]; gender: string[]; score: string[] },
    );
  }, []);
}
