import { makeStyles } from "@gfg/ui-v2/theming";
import { HistoricalFavoriteCategoryBrand } from "@coral/typings";
import SingleList, {
  SelectableListItem,
  SingleListItem,
} from "~/user-profile/components/historical-profile/single-list";
import TableWrapper from "~/user-profile/components/table-wrapper";
import useFavoriteBrandsByCategory from "~/user-profile/hooks/use-favorite-brands-by-category";
import convertScore from "~/user-profile/utils/cocnvert-score";

const useStyles = makeStyles()(({ spacing }) => ({
  listItems: {
    display: "flex",
    justifyContent: "space-between",
  },
  list: {
    "width": "calc(100% / 3)",
    "paddingLeft": spacing("sm"),
    "paddingRight": spacing("sm"),

    "&:first-of-type": {
      paddingRight: spacing("sm"),
      paddingLeft: 0,
    },
    "&:last-of-type": {
      paddingLeft: spacing("sm"),
      paddingRight: 0,
    },
  },
}));

interface Props {
  items: HistoricalFavoriteCategoryBrand[];
}

export default function FavoriteBrands({ items }: Props) {
  const { classes } = useStyles();

  const { brands, scores, categories, selectedCategory, handleCategorySelect } = useFavoriteBrandsByCategory(items);

  return (
    <TableWrapper title="Favorite brands per category">
      <div className={classes.listItems}>
        <SingleList headerText="Categories" isEmpty={categories.length === 0} className={classes.list}>
          {categories.map(item => (
            <SelectableListItem
              onClick={() => handleCategorySelect(item)}
              active={selectedCategory === item}
              key={`${item}`}
            >
              {item}
            </SelectableListItem>
          ))}
        </SingleList>
        <SingleList headerText="Top 5 brands" isEmpty={brands.length === 0} className={classes.list}>
          {brands.map(item => (
            <SingleListItem key={`${item}`}>{item}</SingleListItem>
          ))}
        </SingleList>
        <SingleList headerText="Score" isEmpty={scores.length === 0} className={classes.list}>
          {scores.map(item => (
            <SingleListItem key={`${item}`}>{convertScore(item)}</SingleListItem>
          ))}
        </SingleList>
      </div>
    </TableWrapper>
  );
}
