import { makeStyles } from "@gfg/ui-v2/theming";
import { Theme } from "@gfg/ui-v2/theming/create-theme";
import { ProfileRow } from "@coral/typings";
import RealtimeList from "~/user-profile/components/realtime-profile/list";
import SectionHeader from "~/user-profile/components/section-header";

const useStyles = makeStyles()((theme: Theme) => ({
  tables: {
    padding: theme.spacing("md"),
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: theme.colors.background,
    border: `${theme.size(1)} solid  ${theme.palette.tertiary[200]}`,
    borderRadius: theme.size(4),
    boxShadow: theme.shadow("md"),
  },

  list: {
    "width": "50%",

    "&:first-of-type": {
      paddingRight: theme.spacing("sm"),
    },

    "&:last-of-type": {
      paddingLeft: theme.spacing("sm"),
    },
  },
}));

interface RealTimeProfileProps {
  recentlyViewedBrands: ProfileRow[];
  recentlyViewedCategories: ProfileRow[];
}

export default function RealTimeProfile({ recentlyViewedBrands, recentlyViewedCategories }: RealTimeProfileProps) {
  const { classes } = useStyles();
  return (
    <div>
      <SectionHeader
        title="Real-time user profile"
        description="  The real-time user profile provides a dynamic, continuously updated view of a user's behavior, preferences,
          and interactions, tracked as they occur. In this section, you can access the user's preferred brands and
          categories based on their most recent e-commerce session. The Real-Time User Profile is aggregated with the
          Historical User Profile and cleaned after 30 minutes the user session in the online store finishes."
      />
      <div className={classes.tables}>
        <RealtimeList items={recentlyViewedBrands} headerText="Recently viewed brands" className={classes.list} />
        <RealtimeList
          items={recentlyViewedCategories}
          headerText="Recently viewed categories"
          className={classes.list}
        />
      </div>
    </div>
  );
}
