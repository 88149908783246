import { makeStyles } from "@gfg/ui-v2/theming";
import { HistoricalFavoriteCategoryPriceRange } from "@coral/typings";
import SingleList, {
  SelectableListItem,
  SingleListItem,
} from "~/user-profile/components/historical-profile/single-list";
import TableWrapper from "~/user-profile/components/table-wrapper";
import useFavoriteCategoryPriceRanges from "~/user-profile/hooks/use-favorite-category-price-ranges";
import convertScore from "~/user-profile/utils/cocnvert-score";

const useStyles = makeStyles()(({ spacing }) => ({
  listItems: {
    display: "flex",
    justifyContent: "space-between",
  },
  list: {
    "width": "calc(100% / 3)",
    "paddingLeft": spacing("sm"),
    "paddingRight": spacing("sm"),

    "&:first-of-type": {
      paddingRight: spacing("sm"),
      paddingLeft: 0,
    },
    "&:last-of-type": {
      paddingLeft: spacing("sm"),
      paddingRight: 0,
    },
  },
}));

interface Props {
  items: HistoricalFavoriteCategoryPriceRange[];
}

export default function FavoriteCategoryPriceRanges({ items }: Props) {
  const { classes } = useStyles();

  const { categories, range, score, selectedCategory, handleCategorySelect } = useFavoriteCategoryPriceRanges(items);

  return (
    <TableWrapper title="Favorite price bands per category">
      <div className={classes.listItems}>
        <SingleList headerText="Categories" isEmpty={categories.length === 0} className={classes.list}>
          {categories.map(item => (
            <SelectableListItem
              onClick={() => handleCategorySelect(item)}
              active={selectedCategory === item}
              key={`${item}`}
            >
              {item}
            </SelectableListItem>
          ))}
        </SingleList>
        <SingleList headerText="Price Range" isEmpty={range.length === 0} className={classes.list}>
          {range.map(item => (
            <SingleListItem key={`${item}`}>
              <span>
                from <strong>{item.from}</strong> to <strong>{item.to}</strong>
              </span>
            </SingleListItem>
          ))}
        </SingleList>
        <SingleList headerText="Score" isEmpty={score.length === 0} className={classes.list}>
          {score.map(item => (
            <SingleListItem key={`${item}`}>{convertScore(item)}</SingleListItem>
          ))}
        </SingleList>
      </div>
    </TableWrapper>
  );
}
