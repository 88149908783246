import { HistoricalFavoriteCategoryBrand } from "@coral/typings";
import { useState } from "react";

export default function useFavoriteBrandsByCategory(items: HistoricalFavoriteCategoryBrand[]) {
  const [selectedCategory, setSelectedCategory] = useState(items[0]?.category || "");

  const handleCategorySelect = (category: string) => {
    setSelectedCategory(category);
  };

  const selectedBrands = items.find(item => item.category === selectedCategory)?.brands || [];

  const brands = selectedBrands.map(item => item.value);
  const scores = selectedBrands.map(item => item.score);
  const categories = items
    .map(item => item.category)
    .reduce((acc, item) => {
      if (!acc.includes(item)) {
        acc.push(item);
      }
      return acc;
    }, [] as string[]);

  return {
    brands,
    scores,
    selectedCategory,
    selectedBrands,
    categories,
    handleCategorySelect,
  };
}
